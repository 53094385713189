import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from '../Logger';
import '../toaster.css';
import classnames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TopBarThemeGenerator from '../ThemeGenerators/TopBarThemeGenerator';
const topBarThemeGen = new TopBarThemeGenerator();

const logger = new Logger("Login");

const styles = function (theme) {
    return {
        root:
        {
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--background-color)',
            backgroundImage: `url(${window.config ? window.config.background : null})`,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
        dialogTitle:
        {
        },
        dialogPaper:
        {
            width: '30vw',
            padding: theme.spacing(2),
            [theme.breakpoints.down('lg')]:
            {
                width: '40vw'
            },
            [theme.breakpoints.down('md')]:
            {
                width: '50vw'
            },
            [theme.breakpoints.down('sm')]:
            {
                width: '70vw'
            },
            [theme.breakpoints.down('xs')]:
            {
                width: '90vw'
            }
        },
        logo:
        {
            display: 'block',
            paddingBottom: '1vh'
        },
        loginButton:
        {
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            padding: 0
        },
        largeIcon:
        {
            fontSize: '2em'
        },
        largeAvatar:
        {
            width: 50,
            height: 50
        },
        green:
        {
            color: 'rgba(0, 153, 0, 1)'
        }
    };
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
            {window.config.logo && <img alt='Logo' className={classes.logo} src={window.config.logo} />}
            <Typography variant='h5'>{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root:
    {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root:
    {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const ChooseRoom = ({
    classes,
    _signallingSocket
}) => {
    const intl = useIntl();

    useEffect(() => {
        _signallingSocket.on("auth_response", (request, cb) => {
            switch (request.method) {
                case 'authenticated':
                    // TODO: Maybe redirect to a default room?
                    toast.success("You have logged in!");
                    setTimeout(() => {
                        window.location.replace("/")
                    }, 3000);
                    break;

                case 'invalid_credentials':
                    toast.error("We could not validate these credentials, please try again");
                    break;

                case 'show_login_response':
                    const { show_login, companyname, firstname, lastname, username } = request.data;
                    if (show_login) {
                        toggleShowLogin();
                    } else {
                        setFirstName(firstname);
                        setLastName(lastname);
                        setCompany(companyname);
                    }
                    break;
            }
        });

        // Get if user is already logged in from the server
        var method = "show_login";
        _signallingSocket.emit("auth_action", { method });

        var method = "get_settings_client";
        _signallingSocket.emit("auth_action", { method });
    }, [])

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [rememberMe, setRememberMe] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [companyname, setCompany] = useState();

    const toggleChecked = () => {
        setRememberMe((prev) => !prev);
    };

    const toggleShowLogin = () => {
        setShowLogin((prev) => !prev);
    }

    const login = () => {
        var method = "login";
        var data = {
            "username": username,
            'password': password,
            "remmeber_me": rememberMe
        }
        _signallingSocket.emit("auth_action", { method, data });
    }

    const logout = () => {
        var method = "logout";
        _signallingSocket.emit("auth_action", { method });
        localStorage.removeItem("vipex_live_access_token");
        toggleShowLogin();
    }

    return (
        <div className={classes.root}>
            <Dialog
                open
                classes={{
                    paper: classes.dialogPaper
                }}
            >
                <ToastContainer />

                <DialogTitle>
                    {topBarThemeGen.getFullProductName(window.location.host)}
                    <hr />
                </DialogTitle>
                {showLogin ?
                    <DialogContent>
                        <DialogContentText gutterBottom>
                            <FormattedMessage
                                id='login'
                                defaultMessage='Enter your username and password to login'
                            />
                        </DialogContentText>

                        <TextField
                            id="roomId"
                            label={intl.formatMessage({
                                id: 'login.username',
                                defaultMessage: 'Login'
                            })}
                            variant="outlined"
                            margin="normal"
                            inputProps={{ min: 0, style: { textAlign: "right" } }}
                            onChange={(event) => {
                                const { value } = event.target;
                                setUsername(value);
                            }}
                        />
                        {" "}
                        <TextField
                            label={intl.formatMessage({
                                id: 'login.Password',
                                defaultMessage: 'Password'
                            })}
                            type="password"
                            variant="outlined"
                            margin="normal"
                            onChange={(event) => {
                                const { value } = event.target;
                                setPassword(value);
                            }}
                        />
                        <FormControlLabel
                            className={classnames(classes.setting, classes.switchLabel)}
                            control={
                                <Switch onChange={toggleChecked} />}
                            labelPlacement='start'
                            label={intl.formatMessage({
                                id: 'login.rememberMe',
                                defaultMessage: 'Remember Me'
                            })}
                        />

                    </DialogContent>
                    :
                    <DialogContent>
                        <DialogContentText gutterBottom>
                            <FormattedMessage
                                id='login'
                                defaultMessage={'You are already logged in (' + companyname + ')'}
                            />
                        </DialogContentText>
                    </DialogContent>
                }

                <DialogActions>
                    {showLogin ?
                        <Button
                            onClick={(event) => login(event)}
                            variant='contained'
                            color='secondary'
                        >
                            <FormattedMessage
                                id='login.continue'
                                defaultMessage='Login'
                            />
                        </Button>
                        :
                        <Button
                            onClick={(event) => logout(event)}
                            variant='contained'
                            color='secondary'
                        >
                            <FormattedMessage
                                id='login.continue'
                                defaultMessage='Logout'
                            />
                        </Button>
                    }
                </DialogActions>

                {!isElectron() &&
                    <CookieConsent buttonText={intl.formatMessage({
                        id: 'room.consentUnderstand',
                        defaultMessage: 'I understand'
                    })}
                    >
                        <FormattedMessage
                            id='room.cookieConsent'
                            defaultMessage='This website uses cookies to enhance the user experience'
                        />
                    </CookieConsent>
                }
            </Dialog>
        </div>
    );
};

ChooseRoom.propTypes =
{
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChooseRoom);