import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import randomString from 'random-string';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InputAdornment from "@material-ui/core/InputAdornment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import Logger from '../Logger';
import '../toaster.css';
import TopBarThemeGenerator from '../ThemeGenerators/TopBarThemeGenerator';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classnames from 'classnames';
import { REACT_APP_TITLE } from 'react-native-dotenv'
const topBarThemeGen = new TopBarThemeGenerator();

const logger = new Logger("ChooseRoom");

const styles = function (theme) {
	if (window.location.host.search("nimans") < 0) {
		return {
			root:
			{
				display: 'flex',
				width: '100%',
				height: '100%',
				backgroundColor: 'var(--background-color)',
				backgroundImage: `url(${window.config ? window.config.background : null})`,
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			},
			dialogTitle:
			{
			},
			dialogPaper:
			{
				width: '30vw',
				padding: theme.spacing(2),
				[theme.breakpoints.down('lg')]:
				{
					width: '40vw'
				},
				[theme.breakpoints.down('md')]:
				{
					width: '50vw'
				},
				[theme.breakpoints.down('sm')]:
				{
					width: '70vw'
				},
				[theme.breakpoints.down('xs')]:
				{
					width: '90vw'
				}
			},
			logo:
			{
				display: 'block',
				paddingBottom: '1vh'
			},
			loginButton:
			{
				position: 'absolute',
				right: theme.spacing(2),
				top: theme.spacing(2),
				padding: 0
			},
			largeIcon:
			{
				fontSize: '2em'
			},
			largeAvatar:
			{
				width: 50,
				height: 50
			},
			green:
			{
				color: 'rgba(0, 153, 0, 1)'
			}
		};
	} else {
		return {
			root:
			{
				display: 'flex',
				width: '100%',
				height: '100%',
				backgroundColor: "#00AEEF",
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			},
			dialogTitle:
			{
			},
			dialogPaper:
			{
				width: '30vw',
				padding: theme.spacing(2),
				[theme.breakpoints.down('lg')]:
				{
					width: '40vw'
				},
				[theme.breakpoints.down('md')]:
				{
					width: '50vw'
				},
				[theme.breakpoints.down('sm')]:
				{
					width: '70vw'
				},
				[theme.breakpoints.down('xs')]:
				{
					width: '90vw'
				}
			},
			logo:
			{
				display: 'block',
				paddingBottom: '1vh'
			},
			loginButton:
			{
				position: 'absolute',
				right: theme.spacing(2),
				top: theme.spacing(2),
				padding: 0
			},
			largeIcon:
			{
				fontSize: '2em'
			},
			largeAvatar:
			{
				width: 50,
				height: 50
			},
			green:
			{
				color: 'rgba(0, 153, 0, 1)'
			}
		};
	}
}

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
			{window.config.logo && <img alt='Logo' className={classes.logo} src={window.config.logo} />}
			<Typography variant='h5'>{children}</Typography>
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root:
	{
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root:
	{
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

function startTimer(duration, display) {
	var timer = duration, minutes, seconds;
	setInterval(function () {
		console.log(display)
		if (display) {
			minutes = parseInt(timer / 60, 10)
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			display.textContent = minutes + ":" + seconds;

			if (--timer < 0) {
				timer = duration;
			}
		}
	}, 1000);
}

const ChooseRoom = ({
	classes
}) => {
	const roomNameRegex = /[!#$%^&*(),.?":;{}\[\]£+~\/¬=|<>'#]/g;

	const [roomId, setRoomId] = useState(function () {
		var room_name = randomString({ length: 8 }).toLowerCase();
	});

	const [customerName, setCustomerName] = useState(function () {
		if (customerName === "") {
			return realCustomerName;
		}
	});
	const [realCustomerName, setRealCustomer] = useState();
	const [userName, setUserName] = useState();
	const [ownLoggedIn, setLoggedIn] = useState(false);

	const timeSpan = useCallback(node => {
		if (node !== null) {
			if (global.params["start_time"]) {
				var display = node;
				var duration = global.params["start_time"];

				var timer = duration, minutes, seconds;
				var tid = setInterval(function () {
					console.log(display)
					if (display) {
						minutes = parseInt(timer / 60, 10)
						seconds = parseInt(timer % 60, 10);

						minutes = minutes < 10 ? "0" + minutes : minutes;
						seconds = seconds < 10 ? "0" + seconds : seconds;

						display.textContent = minutes + ":" + seconds;

						if (--timer < 0) {
							clearInterval(tid);
							window.location.href = "/" + document.getElementById("roomId").value
						}
					}
				}, 1000);
			}
		}
	}, []);

	const intl = useIntl();

	const continueToJoin = () => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		fetch('/CheckRoomValid', {
			method: 'POST',
			body: JSON.stringify({
				"access_token": localStorage.getItem("vipex_live_access_token"),
				"roomId": roomId,
				"customerName": customerName ? customerName : realCustomerName,
				"createRoom": false
			}),
			headers: myHeaders
		})
			.then(results => results.json())
			.then(data => {
				if (data.success) {
					toast.success("Joining room");
					var url = roomId + "@" + (customerName ? customerName : realCustomerName) + (userName ? "?display_name=" + userName : "");
					window.location.href = url;
				} else {
					toast.error(data.errorMessage ? data.errorMessage : "The room you are trying to join is not available right now", {
						autoClose: data.autoClose ? data.autoClose : 5000
					});
				}
			});

		fetch('/GetLoginStatus')
			.then(results => results.json())
			.then(data => {
				setLoggedIn(data.loginStatus);
			});
	};

	const disableContinueButton = false;

	const [open, setOpen] = useState(false);
	useEffect(() => {
		const openTimer = setTimeout(() => setOpen(true), 1000);
		const closeTimer = setTimeout(() => setOpen(false), 4000);

		return () => {
			clearTimeout(openTimer);
			clearTimeout(closeTimer);
		};
	}, []);

	useEffect(() => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		fetch('/GetCustomerName', {
			method: 'POST',
			body: JSON.stringify({
				"access_token": localStorage.getItem("vipex_live_access_token"),
			}),
			headers: myHeaders
		})
			.then(results => results.json())
			.then(data => {
				logger.error("Customer name is: " + data._customer_name);
				logger.error("User name is: " + data._user_name);
				if (data._customer_name && data._user_name) {

					const regex = /[!#$%^&*(),.?":;{}\[\]£+~\/¬=|<>'#]/g;
					data._customer_name = data._customer_name.replace(regex, "");
					data._customer_name = data._customer_name.replace(/\s/g, "-");

					data._customer_name = data._customer_name.replace(";", "");

					setRoomId(data._user_name.replace(" ", "-"));
					setUserName(data._user_name.replace(" ", "-"));
					setCustomerName(data._customer_name);
					setRealCustomer(data._customer_name);
				}
			});

	}, []);

	const handleTooltipClose = () => {
		setOpen(false);
	};
	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open
				classes={{
					paper: classes.dialogPaper
				}}
			>
				<ToastContainer
					position="bottom-right"
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<DialogTitle>
					{window.config.REACT_APP_TITLE}
					<hr />
					{window.config.loginEnabled &&
						<Tooltip
							onClose={handleTooltipClose}
							onOpen={handleTooltipOpen}
							title={ownLoggedIn ? "Account" : "Log in"}
							placement='left'
						>
							<IconButton
								aria-label={ownLoggedIn ? "Account" : "Log in"}
								className={classes.loginButton}
								color='inherit'
								href="login"
							>
								{ownLoggedIn ?
									<AccountCircle
										className={classnames(classes.largeIcon, ownLoggedIn ? classes.green : null)}
									/>
									:
									<ExitToAppIcon
										className={classnames(classes.largeIcon, ownLoggedIn ? classes.green : null)}
									/>
								}
							</IconButton>
						</Tooltip>
					}
				</DialogTitle>
				<DialogContent>
					{global.params["error_message"] &&
						<DialogContentText
							align='center'
							style={{ color: "red" }}
						>
							<FormattedMessage
								id='room.noPermission'
								defaultMessage={decodeURI(global.params["error_message"])}
							/>
						</DialogContentText>
					}
					{global.params["message"] &&
						<DialogContentText
							align='center'
							style={{ color: "cornflowerblue" }}
						>
							<div>{decodeURI(global.params["message"])} <span id="time" ref={timeSpan}></span> minutes!</div>
						</DialogContentText>
					}
					<DialogContentText gutterBottom>
						<FormattedMessage
							id='room.chooseRoom'
							defaultMessage='Choose the name of the room you would like to join'
						/>
					</DialogContentText>

					<TextField
						id="roomId"
						label={intl.formatMessage({
							id: 'label.roomName',
							defaultMessage: 'Room Name'
						})}
						variant="outlined"
						value={roomId || ""}
						margin="normal"
						inputProps={{ min: 0, style: { textAlign: "right" } }}
						onChange={(event) => {
							const { value } = event.target;
							var new_val = value.replace(roomNameRegex, "");
							new_val = new_val.replace(" ", "-");
							setRoomId(new_val.toLowerCase());
						}}
						onBlur={() => {
							if (roomId === '')
								setRoomId(randomString({ length: 8 }).toLowerCase());
						}}
					/>
					{" "}
					<TextField
						id="customerId"
						label={intl.formatMessage({
							id: 'label.companyName',
							defaultMessage: 'Company Name'
						})}
						placeholder={realCustomerName}
						variant="outlined"
						margin="normal"
						InputProps={{
							startAdornment: <InputAdornment position="start">@</InputAdornment>
						}}
						onChange={(event) => {
							const { value } = event.target;
							var new_val = value.replace(roomNameRegex, "");
							new_val = new_val.replace(/\s/g, "-");
							setCustomerName(value);
						}}
					/>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={(event) => continueToJoin(event)}
						// to={roomId + "@" + customer_name + (user_name ? "?display_name=" + user_name : "")}
						variant='contained'
						color='secondary'
						disabled={disableContinueButton}
					>
						<FormattedMessage
							id='label.chooseRoomButton'
							defaultMessage='Continue'
						/>
					</Button>
					{/* <Button
						component={Link}
						to={roomId + (user_name ? "?display_name=" + user_name : "")}
						variant='contained'
						color='secondary'
					>
						<FormattedMessage
							id='label.chooseRoomButton'
							defaultMessage='Continue'
						/>
					</Button> */}
				</DialogActions>

				{!isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id: 'room.consentUnderstand',
						defaultMessage: 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
				}
			</Dialog>
		</div>
	);
};

ChooseRoom.propTypes =
{
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChooseRoom);