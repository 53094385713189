class TopBarThemeGenerator {
    getProductName (url) {
        if (stringContains(url, "nimans")) {
            return "Video"
        } else {
            return "vipeX Live"
        }
    }

    getFullProductName (url) {
        return process.env.APP_TITLE
    }

    getMainTheme (theme, url) {
        // Nimans Theme
        if (stringContains(url, "nimans")) {
            return {
                persistentDrawerOpen :
                {
                    width                          : 'calc(100% - 30vw)',
                    marginLeft                     : '30vw',
                    [theme.breakpoints.down('lg')] :
                    {
                        width      : 'calc(100% - 40vw)',
                        marginLeft : '40vw'
                    },
                    [theme.breakpoints.down('md')] :
                    {
                        width      : 'calc(100% - 50vw)',
                        marginLeft : '50vw'
                    },
                    [theme.breakpoints.down('sm')] :
                    {
                        width      : 'calc(100% - 70vw)',
                        marginLeft : '70vw'
                    },
                    [theme.breakpoints.down('xs')] :
                    {
                        width      : 'calc(100% - 90vw)',
                        marginLeft : '90vw'
                    }
                },
                menuButton :
                {
                    margin  : 0,
                    padding : 0
                },
                logo :
                {
                    display                      : 'none',
                    marginLeft                   : 20,
                    width                        : "9%",
                    [theme.breakpoints.up('sm')] :
                    {
                        display : 'block'
                    }
                },
                divider :
                {
                    marginLeft : theme.spacing(3)
                },
                show :
                {
                    opacity    : 1,
                    transition : 'opacity .5s'
                },
                hide :
                {
                    opacity    : 0,
                    transition : 'opacity .5s'
                },
                grow :
                {
                    flexGrow : 1
                },
                title :
                {
                    display                      : 'none',
                    marginLeft                   : 20,
                    [theme.breakpoints.up('sm')] :
                    {
                        display                  : 'block'
                    },
                    color                        : "black",
                    fontWeight                   : "bold",
                    fontSize                     : "27px"
                },
                sectionDesktop : {
                    display                      : 'none',
                    [theme.breakpoints.up('md')] : {
                        display : 'flex'
                    }
                },
                sectionMobile : {
                    display                      : 'flex',
                    [theme.breakpoints.up('md')] : {
                        display : 'none'
                    }
                },
                actionButton :
                {
                    margin  : theme.spacing(1, 0),
                    padding : theme.spacing(0, 1)
                },
                disabledButton :
                {
                    margin : theme.spacing(1, 0)
                },
                green :
                {
                    color : 'rgba(0, 153, 0, 1)'
                },
                moreAction :
                {
                    margin : theme.spacing(0.5, 0, 0.5, 1.5)
                },
                copyInviteText : 
                {
                    color : "black",
                }
            }
        } else {
            // Default Theme
            return {
                persistentDrawerOpen :
                {
                    width                          : 'calc(100% - 30vw)',
                    marginLeft                     : '30vw',
                    [theme.breakpoints.down('lg')] :
                    {
                        width      : 'calc(100% - 40vw)',
                        marginLeft : '40vw'
                    },
                    [theme.breakpoints.down('md')] :
                    {
                        width      : 'calc(100% - 50vw)',
                        marginLeft : '50vw'
                    },
                    [theme.breakpoints.down('sm')] :
                    {
                        width      : 'calc(100% - 70vw)',
                        marginLeft : '70vw'
                    },
                    [theme.breakpoints.down('xs')] :
                    {
                        width      : 'calc(100% - 90vw)',
                        marginLeft : '90vw'
                    }
                },
                menuButton :
                {
                    margin  : 0,
                    padding : 0
                },
                coloredButton : 
                {
                    color   : "white"
                },
                logo :
                {
                    display                      : 'none',
                    marginLeft                   : 20,
                    [theme.breakpoints.up('sm')] :
                    {
                        display : 'block'
                    }
                },
                divider :
                {
                    marginLeft : theme.spacing(3)
                },
                show :
                {
                    opacity    : 1,
                    transition : 'opacity .5s'
                },
                hide :
                {
                    opacity    : 0,
                    transition : 'opacity .5s'
                },
                grow :
                {
                    flexGrow : 1
                },
                title :
                {
                    display                      : 'none',
                    marginLeft                   : 20,
                    [theme.breakpoints.up('sm')] :
                    {
                        display : 'block'
                    }
                },
                sectionDesktop : {
                    display                      : 'none',
                    [theme.breakpoints.up('md')] : {
                        display : 'flex'
                    }
                },
                sectionMobile : {
                    display                      : 'flex',
                    [theme.breakpoints.up('md')] : {
                        display : 'none'
                    }
                },
                actionButton :
                {
                    margin  : theme.spacing(1, 0),
                    padding : theme.spacing(0, 1)
                },
                disabledButton :
                {
                    margin : theme.spacing(1, 0)
                },
                green :
                {
                    color : 'rgba(0, 153, 0, 1)'
                },
                moreAction :
                {
                    margin : theme.spacing(0.5, 0, 0.5, 1.5)
                }
            }
        }
    }
}

function stringContains (str, term) {
    if (str.search(term) < 0)    {
        return false 
    } else {
        return true
    }
}

export default TopBarThemeGenerator;